import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// general components
import Home from "./components/Home";
import Signup from "./components/auth/Signup";
import NoMatch from "./components/noMatch/NoMatch";
// user components
import AuthDashboard from "./components/user/authDashboard/AuthDashboard";
import Dashboard from "./components/user/dashboard/Dashboard";
// admin components
import AuthAdminDashboard from "./components/admin/authAdminDashboard/AuthAdminDashboard";
import AdminHomePag from "./components/admin/adminHomePage/AdminHomePage";

import i18n from "./i18n";
import languageConstants from "./constants/LanguageConstants";
import HomeLayout from "./components/mainLayout/homeLayout/HomeLayout";
import UserLayout from "./components/user/mainUserLayout/userLayout/UserLayout";
import Subscriptions from "./components/user/Subscriptions";
import Project from "./components/user/project/Project";
import ResetPassword from "./components/auth/ResetPassword";
import NewPassword from "./components/auth/NewPassword";
import Login from "./components/auth/Login";
import SendVerifyMail from "./components/auth/SendVerifyMail";
import TermsOfUse from "./components/auth/TermsOfUse";
import PrivacyPolicy from "./components/auth/PrivacyPolicy";
import NotSupportedPhoneScreen from "./components/auth/NotSupportedPhoneScreen";
import { UserProvider } from "./context/userContext";
import SallaWelcoming from "./components/salla/SallaWelcoming";
import AxiosInterceptors from "./utils/AxiosInterceptors";
import DemoStores from "./components/demo-stores/DemoStores";
import CouponUseCount from "./components/capone-use-count/CouponUseCount";
import CreateStore from "./components/auth/CreateStore";

function App() {
  const [language, setLanguage] = useState(i18n.language);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  function thereIsLanguageInlocalStorage() {
    return localStorage.getItem("i18nextLng") != null;
  }

  useEffect(() => {
    // check if there is already language in localStorage
    if (thereIsLanguageInlocalStorage()) {
      i18n.changeLanguage(localStorage.getItem("i18nextLng"));
      document.body.dir = i18n.dir();
    } else {
      i18n.changeLanguage(navigator.language);
      document.body.dir = i18n.dir();
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <UserProvider>
          <ToastContainer
            position='top-center'
            autoClose={5000}
            rtl={language === languageConstants.AR}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <AxiosInterceptors />
          <Routes>
            <Route
              path='/not-supported-phone-screen'
              element={<NotSupportedPhoneScreen />}
            />
            <Route path='/demo-stores' element={<DemoStores />} />
            {/* general Routes */}
            <Route
              element={
                <HomeLayout language={language} setLanguage={setLanguage} />
              }
            >
              <Route path='/' element={<Home />} />
              <Route
                path='/salla'
                element={
                  <SallaWelcoming
                    language={language}
                    setLanguage={setLanguage}
                  />
                }
              />
              <Route
                path='/login'
                element={
                  <Login language={language} setLanguage={setLanguage} />
                }
              />
              <Route
                path='/signup'
                element={
                  <Signup language={language} setLanguage={setLanguage} />
                }
              />
              <Route
                path='/create-store'
                element={
                  <CreateStore language={language} setLanguage={setLanguage} />
                }
              />
              <Route
                path='/reset-password'
                element={
                  <ResetPassword
                    language={language}
                    setLanguage={setLanguage}
                  />
                }
              />
              <Route
                path='/new-password'
                element={
                  <NewPassword language={language} setLanguage={setLanguage} />
                }
              />
              <Route path='/send-verify-mail' element={<SendVerifyMail />} />
              <Route
                path='/terms-of-use'
                element={
                  <TermsOfUse language={language} setLanguage={setLanguage} />
                }
              />
              <Route
                path='/privacy-policy'
                element={
                  <PrivacyPolicy
                    language={language}
                    setLanguage={setLanguage}
                  />
                }
              />
              <Route
                path='/coupon-use-count'
                element={
                  <CouponUseCount
                    language={language}
                    setLanguage={setLanguage}
                  />
                }
              />
              <Route path='*' element={<NoMatch />} />
            </Route>

            {/* user Routes */}
            <Route element={<AuthDashboard />}>
              <Route
                element={
                  <UserLayout language={language} setLanguage={setLanguage} />
                }
              >
                <Route
                  path='dashboard'
                  element={<Dashboard language={language} />}
                />
                <Route
                  path='dashboard/:id'
                  element={<Project language={language} />}
                />
                <Route path='subscriptions' element={<Subscriptions />} />
              </Route>
            </Route>

            {/* Admin Routes */}
            <Route element={<AuthAdminDashboard />}>
              <Route path='/admin-dashboard' element={<AdminHomePag />} />
            </Route>
          </Routes>
        </UserProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
