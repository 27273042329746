import projectConstants from "../constants/ProjectConstants";
import videosConstants from "../constants/VideosConstants";

export const ar = {
    translation: {
        general: {
            delete: "حذف",
            messages: {
                success: {
                    SignedOut: "تم تسجيل الخروج بنجاح"
                },
                error: {
                    generalError: "خطأ عام",
                    connectionRefused: "رفض اتصال",
                    loginFirst: "يرجى تسجيل الدخول أولا",
                    userNotAuthorized: "مستخدم غير مصرح به",
                    loginAgain: "الرجاء تسجيل الدخول مرة أخرى",
                },
                info: {
                    verificationInProgress: "جاري التحقق",
                },
                confirm: {
                    deleteProject: "هل أنت متأكد أنك تريد حذف هذا المشروع؟",
                    deletePart: "هل أنت متأكد أنك تريد حذف هذا المقطع؟"
                }
            }
        },
        home: {
            header: "صفحة اقوى مشروع بالعالم!",
            login: "تسجيل الدخول",
            signUp: "تسجيل جديد",
            main: "الرئيسية",
        },
        login: {
            email: "البريد الإلكتروني",
            password: "كلمة المرور",
            logIn: "دخول",
            forgetPassword: "نسيت كلمة المرور؟",
            messages: {
                success: {
                    loggedInSuccessfully: "تم تسجيل الدخول بنجاح"
                },
                error: {
                },
            }
        },
        signUp: {
            name: "الإسم",
            phone: "رقم الجوال",
            email: "البريد الإلكتروني",
            storeName: "اسم المتجر / الموقع",
            storeUrl: "رابط المتجر / الموقع",
            password: "كلمة المرور",
            passwordConfirmation: "تأكيد كلمة المرور",
            iAgreeToThe: "موافق على",
            termsAndConditions: "الشروط و الأحكام",
            and: "و",
            privacyPolicy: "سياسة الخصوصية",
            register: "تسجيل",
            messages: {
                success: {
                    accountCreatedSuccessfully: "تم إنشاء حساب بنجاح"
                },
                error: {
                },
            },
            noteHeader: "شكرا لاهتمامك التسجيل في أكتيڤيد",
            noteSubHeader: "يمكنك التسجيل من خلال القنوات التالية",
            channel: "القناة",
            status: "الحالة",
            actions: "إجراءات",
            activid: "أكتيڤيد",
            salla: "سلة",
            zid: "زد",
            customWebsite: "موقع مخصص ؟",
            available: "متاح",
            soon: "قريبا",
            registerNow: "سجل الان",
            tableCaption: "للملاحظات يمكنكم التواصل معنا عبر القنوات التالية"
        },
        resetPassword: {
            email: "بريدك الإلكتروني",
            reset: "إستعادة",
            resetPassword: "إستعادة كلمة المرور",
            messages: {
                success: {
                },
                error: {
                },
            }
        },
        newPassword: {
            newPassword: "كلمة المرور الجديدة",
            newPasswordConfirmation: "تأكيد كلمة المرور الجديدة",
            save: "حفظ",
            messages: {
                success: {
                },
                error: {
                },
            }
        },
        userDashboard: {
            projects: "المشاريع",
            myVideos: "مقاطعي",
            settings: "الإعدادات",
            packages: "الباقات",
            building: "بناء",
            rules: "القواعد",
            design: "التصميم",
            deploy: "النشر",
            statistics: "الإحصاءيات",
            subscriptions: "اشتراكاتي",
        },
        building: {
            name: 'اسم الفيديو',
            buttons: 'الأزرار',
            buttonText: 'أدخل نص الزر',
            anotherVideo: 'فيديو آخر',
            buttonType: 'الوظيفة',
            videos: 'الفيديوهات',
            addVideo: 'إضافة فيديو',
            copyableText: 'نص قابل للنسخ',
            externalLink: 'رابط خارجي',
            addButton: 'إضافة زر',
            submit: 'موافق',
            selectNextVid: "اختر الفيديو التالي",
            link: "رابط",
        },
        emptyState: {
            note: "ملاحظة",
            greeting: "أهلاً وسهلاً ،",
            startNow: "إبدأ الآن بإنشاء الفيديو التفاعلي الأول لك",
            createBtn: "إنـشـاء",
            uploadBtn: "رفــــــع",
            uploadAgainBtn: "رفع ملف آخر",
            uploadSizeMsg: "تأكد من أن حجم ملفك لا يتجاوز",
            uploadMsg: "اسحب وأفلت مقاطع الفيديو هنا، أو انقر لتحديد مقاطع الفيديو",
            videosLimit: "عدد الفيديوهات المتاح لك رفعها حسب اشتراكك هو:",
            errorMessageMaxSize: "حجم الملف زاد عن الحد الأقصى للحجم المسموح",
            errorMessageType: "نوع الملف غير مسموح",
            errorMessageGeneral: "حدث خطأ، الرجاء المحاولة لاحقاً",
            uploadedSuccessfully: "تم الرفع بـنجاح",
            errorMessageMaxFiles: `لا يمكن رفع أكثر من ${videosConstants.MAX_FILES} ملف`,
            files: "الملفات"
        },
        newProject: {
            createIntractiveVid: "إنشاء فيديو تفاعلي",
            clickToEdit: "إضغط على المشروع لتعديله :",
        },
        formik: {
            required: "مطلوب",
            email: "يجب أن يكون بتنسيق البريد الإلكتروني",
            phone: "رقم الهاتف غير صالح",
            passwordConfirmation: "يجب أن تتطابق كلمات المرور",
            minPassword: "يجب ألا تقل كلمة المرور عن 8 أحرف",
            terms: "يجب عليك قبول الشروط والأحكام",
            validURL: "أدخل عنوان URL صالحًا",
        },
        notSupportedPhoneScreen: {
            title: "عذراً",
            message: "الموقع في الوقت الحالي متوافق مع اجهزة الكمبيوتر فقط",
            back: "رجوع"
        },
        projectDesign: {
            widgetDesign: "الواجهة الخارجية",
            modalDesign: "الواجهة الداخلية",
            soon: "قريباً",
            direction: "الإتجاه",
            shape: "الشكل",
            caption: "التسمية",
            captionPlaceholder: "تحتاج مساعدة ؟",
            borderColor: "لون الإطار",
            customGif: "صورة متحركة مخصصة",
            uploadGif: "رفع صورة متحركة",
            sytle: {
                LEFT: "يسار",
                RIGHT: "يمين",
                CIRCLE: "دائري",
                RECTANGULAR: "مستطيل"
            }
        },
        projectSettings: {
            projectName: "إسم المشروع",
            projectNameCaptionPlaceholder: "مشروع بدون إسم",
            deleteProject: "حذف المشروع"

        },
        projectItem: {
            unnamedProject: "مشروع بدون إسم"
        },
        projectRules: {
            add: "إضافة قاعدة",
            urlsRules: "قاعدة للروابط",
            equalsTo: "يساوي",
            pages: "الصفحات",
            pagesDescription: "قم بتعيين الصفحات التي تسمح بعرض مقاطع الفيديو الخاصة بك حسب عنوان URL",

        },
        projectDeploy: {
            projectStatus: "حالة المشروع",
            alertHeading: "تمَّ نشر مشروعك بنجاح!",
            alertText: "لإجراء التعديلات، يُرجى تعليق المشروع أولًا",
            retryAgain: "أعد المحاولة مرة أخرى",
            notVerifiedEmail: {
                message: "لقد تحققت من حسابي ، ولكن حتى الآن المشروغ غير مكتمل",
                action: "انقر هنا ، دعنا نتحقق"
            },
            messages: {
                success: {
                    accountActivated: "تم تفعيل حسابك"
                },
                error: {
                    alreadySent: "تم ارسال مسبقا ، يرجى الانتظار",
                    accountNotActivated: "حسابك غير مفعل"
                },
            }
        },
        title: {
            projectName: "أكتيڤيد",
            newPass: "تغيير كلمة المرور",
        },
        brief: {
            build: "من هنا يمكنك رفع فيديوهات المشروع والتحكم في الخيارات",
            design: "من هنا يمكنك التحكّم في مظهر مشروعك داخل موقعك",
            rules: "من هنا يمكنك وضع شروط لظهور مشروعك",
            settings: "هنا يمكنك التحكم بإعدادات المشروع وإمكانية إزالته",
            deploy: "هنا تظهر لك حالة الطلب وشرح الخطوة التالية لـنشره على موقعك"
        },
        noMatchPage: {
            header: "نأسف، الرابط غير موجود",
            button: "الصفحة الرئيسية",
        },
        updatePasswordDialog: {
            updateMessageNote: "تم إرسال اسم المستخدم وكلمة المرور إلى بريدك",
            updateMessageAction: "تحديث كلمة المرور",
            update: "تحديث",
            messages: {
                success: {
                    updatedSuccessfully: "تم تحديث كلمة المرور بنجاح"
                },
                error: {
                },
            }
        },
        part: {
            disabledAddButton: `لا يمكنك إضافة أكثر من ${projectConstants.MAX_OPTIONS_PER_PROJECT} خيارات في الوقت الحالي`
        },
        partsList: {
            disabledAddVideo1: 'لا يمكنك إضافة أكثر من',
            disabledAddVideo2: 'مقاطع في الوقت الحالي'
        },
        couponUseCount: {
            coupon: 'كوبون',
            numberOfUsage: 'عدد الاستخدام',
            stores: 'المتاجر',
            store: 'المتجر',
            subscriptionDate: 'تاريخ الاشتراك',
        }
    }
};

