import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import UserNavBar from "../userNavBar/UserNavBar";
import { Box } from "@chakra-ui/layout";

function UserLayout({ language, setLanguage }) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;

  const [activePage, setActivePage] = useState("");

  return (
    <div className='bg-secondary min-h-screen overflow-hidden'>
      <UserNavBar language={language} setLanguage={setLanguage} />
      <div className='flex'>
        <div className='bg-secondary w-52 flex flex-col gap-10 mt-10 pt-14 h-screen fixed'>
          <div
            className='text-2xl text-white cursor-pointer mt-5 flex justify-between'
            onClick={() => {
              navigate("dashboard");
              setActivePage("dashboard");
            }}
          >
            <span className='bg-primary w-8'></span>
            <p
              className={`w-full text-center transition-all ease-in py-1 ${
                pathname.includes("dashboard") || activePage === "dashboard"
                  ? "bg-primary hover:text-secondary"
                  : "hover:text-primary"
              }`}
            >
              {t("userDashboard.projects")}
            </p>
          </div>
          <div
            className='text-2xl text-white cursor-pointer mt-2 flex justify-between'
            onClick={() => {
              navigate("subscriptions");
              setActivePage("subscriptions");
            }}
          >
            <span className='bg-primary w-8'></span>
            <p
              className={`w-full text-center	transition-all ease-in py-1 ${
                pathname.includes("subscriptions") ||
                activePage === "subscriptions"
                  ? "bg-primary hover:text-secondary"
                  : "hover:text-primary"
              }`}
            >
              {t("userDashboard.subscriptions")}
            </p>
          </div>
        </div>
        <Box
          backgroundColor='white'
          minH='90vh'
          h='90vh'
          overflowY='auto'
          roundedTop='5.5rem'
          ms='13rem'
          w='full'
        >
          <Box mt='3.5rem' me='1rem'>
            <Outlet />
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default UserLayout;
