import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
} from "@chakra-ui/react";
import axoisConfigUtil from "../../utils/AxoisConfigUtil";
import axios from "axios";
import Loader from "../Loader";

export default function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    document.title = `${t("title.projectName")} | ${t(
      "userDashboard.subscriptions"
    )}`;
  }, []);

  useEffect(() => {
    getSubscriptions();
  }, []);

  function getSubscriptions() {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/subscriptions`,
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then(function (response) {
        setSubscriptions(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <Center height='300px' w='full' display='flex' flexDir='column'>
        <Loader isLoading={true} />
      </Center>
    );
  }

  return (
    <div className='w-11/12 mx-auto text-[#554B86]'>
      <Tabs variant='soft-rounded' colorScheme='gray'>
        <TabList className='border pr-2 py-2 w-[18rem] rounded-3xl'>
          <Tab
            fontWeight='light'
            fontSize='sm'
            _selected={{ color: "#554B86", bg: "#EFF0F7" }}
          >
            الاشتراكات السابقة
          </Tab>
          <Tab
            fontWeight='light'
            fontSize='sm'
            _selected={{ color: "#554B86", bg: "#EFF0F7" }}
          >
            الاشتراك الحالي
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel mt='10' className='!p-0'>
            {subscriptions.previous_subscriptions.length > 0 ? (
              subscriptions.previous_subscriptions.map((subscription, i) => {
                return (
                  <div key={i} className='flex gap-6 flex-wrap'>
                    <div className='w-60 flex flex-col gap-4'>
                      <p className='text-[#4B427982] text-center'>
                        بداية الاشتراك
                      </p>
                      <div className='bg-[#F3FAFF] h-16 flex justify-center items-center rounded-md'>
                        <p className='text-[#574B90]'>
                          {subscription.subscription_start}
                        </p>
                      </div>
                    </div>
                    <div className='w-60 flex flex-col gap-4'>
                      <p className='text-[#4B427982] text-center'>
                        نهاية الاشتراك
                      </p>
                      <div className='bg-[#F3FAFF] h-16 flex justify-center items-center rounded-md'>
                        <p className='text-[#574B90]'>
                          {subscription.subscription_end}
                        </p>
                      </div>
                    </div>
                    <div className='w-60 flex flex-col gap-4'>
                      <p className='text-[#4B427982] text-center'>المنصة</p>
                      <div className='bg-[#F3FAFF] h-16 flex justify-center items-center rounded-md'>
                        <p className='text-[#574B90]'>
                          {subscription.platform_name}
                        </p>
                      </div>
                    </div>
                    <div className='w-60 flex flex-col gap-4'>
                      <p className='text-[#4B427982] text-center'>اسم الباقة</p>
                      <div className='bg-[#F3FAFF] h-16 flex justify-center items-center rounded-md'>
                        <p className='text-[#574B90]'>
                          {subscription.plan_name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='flex justify-center items-center mt-10'>
                <p className='text-[#4B4279] text-xl'>لايوجد اشتراكات سابقة</p>
              </div>
            )}
          </TabPanel>

          <TabPanel mt='10' className='!p-0'>
            {subscriptions.active_subscriptions.length > 0 ? (
              subscriptions.active_subscriptions.map((subscription, i) => {
                return (
                  <div key={i} className='flex gap-6 flex-wrap'>
                    <div className='w-60 flex flex-col gap-4'>
                      <p className='text-[#4B427982] text-center'>
                        بداية الاشتراك
                      </p>
                      <div className='bg-[#F3FAFF] h-16 flex justify-center items-center rounded-md'>
                        <p className='text-[#574B90]'>
                          {subscription.subscription_start}
                        </p>
                      </div>
                    </div>
                    <div className='w-60 flex flex-col gap-4'>
                      <p className='text-[#4B427982] text-center'>
                        نهاية الاشتراك
                      </p>
                      <div className='bg-[#F3FAFF] h-16 flex justify-center items-center rounded-md'>
                        <p className='text-[#574B90]'>
                          {subscription.subscription_end}
                        </p>
                      </div>
                    </div>
                    <div className='w-60 flex flex-col gap-4'>
                      <p className='text-[#4B427982] text-center'>المنصة</p>
                      <div className='bg-[#F3FAFF] h-16 flex justify-center items-center rounded-md'>
                        <p className='text-[#574B90]'>
                          {subscription?.platform_name || "-"}
                        </p>
                      </div>
                    </div>
                    <div className='w-60 flex flex-col gap-4'>
                      <p className='text-[#4B427982] text-center'>اسم الباقة</p>
                      <div className='bg-[#F3FAFF] h-16 flex justify-center items-center rounded-md'>
                        <p className='text-[#574B90]'>
                          {subscription?.plan_name || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='flex justify-center items-center mt-10'>
                <p className='text-[#4B4279] text-xl'>لايوجد اشتراك حالي</p>
              </div>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}
